import {
  alpha,
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  styled,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import {
  IMG_USER_GROUP_ANALYTICS_ICON,
  IMG_ROLE_ANALYTICS_ICON,
  IMG_USER_ANALYTICS_ICON,
  IMG_DISTRICT_ANALYTICS_ICON,
  IMG_AREA_ANALYTICS_ICON,
  IMG_SCHOOL_ANALYTICS_ICON
} from '../../utils/imageUrls';
import { Chart } from 'react-google-charts';
import { colorPrimary, colorSecondary, colorWhite } from '../../config/theme';
import { useEffect, useRef, useState } from 'react';
import {
  Copy05Icon,
  Expand05Icon,
  FileDownload03Icon,
  FilterLinesIcon,
  ShareThreeDotsIcon,
  User01Icon,
  XClose
} from '../../utils/iconSvg';
import {
  getLocationAnalytics,
  getUserAnalytics,
  getUserAndRoleAnalytics,
  revertLocationAnalytics,
  revertUserAndRoleAnalytics
} from '../../redux/reducers/analyticsSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createTitle, isArrayNotEmpty } from '../../utils/utils';
import {
  getArea,
  getDistrict,
  getSchools,
  getState
} from '../../redux/reducers/mastersSlice';

import { Legend, TileCountComponent } from './UserAndRoleAnalytics';
import { analyticsTypeHeaderContent } from '../../pages/ProductAnalysis';
import html2canvas from 'html2canvas';
import SnackBarBox from '../../components/SnackBarBox';

const LocationAnalytics = () => {
  const dispatch = useDispatch();
  const chartRef = useRef();
  const [char1, setChart1] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [anchorEl, setAnchorEl] = useState({ anchor: null, id: null });
  const [chart, setChart] = useState('');
  const [chart1Payload, setChart1Payload] = useState({
    stateId: 'all',
    districtId: 'all',
    area: 'all',
    school: 'all'
  });
  const [copySuccess, setCopySuccess] = useState(false);
  const {
    isLocationAnalyticsLoading,
    locationAnalyticsData,
    metaDataState,
    metaDataArea,
    metaDataDistrict,
    metaDataSchool
  } = useSelector(state => state.analytics);

  useEffect(() => {
    document.title = createTitle('Location Analytics');

    return () => {
      dispatch(revertLocationAnalytics());
    };
  }, []);

  useEffect(() => {
    let payload = {
      stateId: chart1Payload.stateId == 'all' ? null : chart1Payload.stateId,
      districtId:
        chart1Payload.districtId == 'all' ? null : chart1Payload.districtId,
      area: chart1Payload.area == 'all' ? null : chart1Payload.area,
      school: chart1Payload.school == 'all' ? null : chart1Payload.school
    };
    dispatch(getLocationAnalytics(payload));
  }, [chart1Payload]);

  const handleClickOpen = id => {
    setChart(id);
  };

  const handleClose = () => {
    setChart('');
  };

  const handleDownload = async ({ id = null }) => {
    const canvas = await html2canvas(chartRef.current);
    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = 'geo_chart.png';
    link.click();
  };

  const handleShareClick = (event, id) => {
    setAnchorEl({ anchor: event.currentTarget, id: id });
  };

  const handleShareClose = () => {
    setAnchorEl({ anchor: null, id: null });
    setTimeout(() => {
      setCopySuccess(false);
    }, 4000);
  };

  const handleCopyLink = id => {
    let ids = {
      1: 'locationChart'
    };
    let shareLink = `${process.env.REACT_APP_PORTAL_URL}share-charts?chartType=${ids[id]}`;

    navigator.clipboard.writeText(shareLink);
    setCopySuccess(true);
    handleShareClose();
  };

  const handleRegionSelect = () => {
    let c = char1;
    console.log(char1.getChart(), 'cal');
  };

  return (
    <>
      <Typography mb={2} fontSize={'32px'} fontWeight={'700'}>
        {analyticsTypeHeaderContent['location']}
      </Typography>
      <Grid
        component={Paper}
        elevation={0}
        sx={{ borderRadius: '8px', padding: 2, display: 'flex', width: '100%' }}
      >
        <TileCountComponent
          icon={IMG_DISTRICT_ANALYTICS_ICON}
          count={locationAnalyticsData.totalDistrict}
          description={'Total Districts'}
        />
        <TileCountComponent
          icon={IMG_AREA_ANALYTICS_ICON}
          count={locationAnalyticsData.totalArea}
          description={'Total Areas'}
        />
        <TileCountComponent
          icon={IMG_SCHOOL_ANALYTICS_ICON}
          count={locationAnalyticsData.totalSchool}
          description={'Total Schools'}
        />
      </Grid>
      <Grid
        mt={2}
        container
        display={'flex'}
        width={'100%'}
        flexDirection={'column'}
        sx={{ borderRadius: '8px', padding: 2, bgcolor: 'white' }}
      >
        <Grid item sx={12} display={'flex'} mb={1}>
          <FormControl fullWidth sx={{ marginRight: 1 }}>
            <Select
              size="small"
              value={chart1Payload.stateId}
              onChange={e => {
                setChart1Payload({
                  ...chart1Payload,
                  stateId: e.target.value,
                  districtId: 'all',
                  area: 'all',
                  school: 'all'
                });
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&:hover  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8
                }
              }}
              renderValue={(val, val1) => {
                let value =
                  isArrayNotEmpty(metaDataState) &&
                  metaDataState.find((row, key) => row.stateId === val);

                return val === 'all' ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#ABB4BD'
                    }}
                  >
                    Select state
                  </Typography>
                ) : (
                  value?.stateName
                );
              }}
            >
              {isArrayNotEmpty(metaDataState) &&
                metaDataState.map((row, key) => {
                  return (
                    <MenuItem
                      key={key}
                      value={row.stateId}
                      sx={{
                        ':hover': {
                          backgroundColor: colorSecondary
                        }
                      }}
                    >
                      {row?.stateName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>{' '}
          <FormControl fullWidth sx={{ marginRight: 1 }}>
            <Select
              size="small"
              value={chart1Payload.districtId}
              onChange={e =>
                setChart1Payload({
                  ...chart1Payload,
                  districtId: e.target.value,
                  area: 'all',
                  school: 'all'
                })
              }
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&:hover  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8
                }
              }}
              renderValue={val => {
                let value =
                  isArrayNotEmpty(metaDataDistrict) &&
                  metaDataDistrict.find((row, key) => row.districtId === val);

                return val === 'all' ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#ABB4BD'
                    }}
                  >
                    Select district
                  </Typography>
                ) : (
                  value?.districtName
                );
              }}
            >
              {isArrayNotEmpty(metaDataDistrict) &&
                metaDataDistrict
                  .filter(cc => cc.stateId === chart1Payload.stateId)
                  .map((row, key) => {
                    return (
                      <MenuItem
                        key={key}
                        value={row?.districtId}
                        sx={{
                          ':hover': {
                            backgroundColor: colorSecondary
                          }
                        }}
                      >
                        {row?.districtName}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>{' '}
          <FormControl fullWidth sx={{ marginRight: 1 }}>
            <Select
              size="small"
              value={chart1Payload.area}
              onChange={e =>
                setChart1Payload({
                  ...chart1Payload,
                  area: e.target.value,
                  school: 'all'
                })
              }
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&:hover  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8
                }
              }}
              renderValue={val => {
                let value =
                  isArrayNotEmpty(metaDataArea) &&
                  metaDataArea.find((row, key) => row.pinId === val);

                return val == 'all' ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#ABB4BD'
                    }}
                  >
                    Select area
                  </Typography>
                ) : (
                  value?.area
                );
              }}
            >
              {isArrayNotEmpty(metaDataArea) &&
                metaDataArea
                  .filter(
                    cc =>
                      cc.stateId === chart1Payload.stateId &&
                      cc.districtId === chart1Payload.districtId
                  )
                  .map((row, key) => {
                    return (
                      <MenuItem
                        key={key}
                        value={row?.pinId}
                        sx={{
                          ':hover': {
                            backgroundColor: colorSecondary
                          }
                        }}
                      >
                        {row?.area}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ marginRight: 1 }}>
            <Select
              size="small"
              value={chart1Payload.school}
              onChange={e => {
                setChart1Payload({
                  ...chart1Payload,
                  school: e.target.value
                });
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&:hover  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#CBD2D9'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8
                }
              }}
              renderValue={val => {
                let value =
                  isArrayNotEmpty(metaDataSchool) &&
                  metaDataSchool.find((row, key) => row.schoolId === val);

                return val == 'all' ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#ABB4BD'
                    }}
                  >
                    Select school
                  </Typography>
                ) : (
                  value?.schoolName
                );
              }}
            >
              {isArrayNotEmpty(metaDataSchool) &&
                metaDataSchool
                  .filter(
                    cc =>
                      cc.stateId === chart1Payload.stateId &&
                      cc.districtId === chart1Payload.districtId &&
                      cc.pinId === chart1Payload.area
                  )
                  .map((row, key) => {
                    return (
                      <MenuItem
                        key={key}
                        value={row?.schoolId}
                        sx={{
                          ':hover': {
                            backgroundColor: colorSecondary
                          }
                        }}
                      >
                        {row?.schoolName}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sx={{ display: 'flex', alignSelf: 'end' }}>
          <Legend series={[]} />
          <Tooltip title="Resize">
            {' '}
            <IconButton onClick={() => handleClickOpen(1)} sx={{ mx: 0.5 }}>
              <SvgIcon sx={{ height: 20, width: 20 }}>
                <Expand05Icon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Download image">
            <IconButton
              onClick={() => {
                handleDownload({ id: 1 });
              }}
              sx={{ mr: 0.5 }}
            >
              <SvgIcon sx={{ height: 20, width: 20 }}>
                <FileDownload03Icon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Share">
            {' '}
            <IconButton onClick={e => handleShareClick(e, 1)}>
              <SvgIcon sx={{ height: 20, width: 20 }}>
                <ShareThreeDotsIcon />
              </SvgIcon>
            </IconButton>{' '}
          </Tooltip>

          <Menu
            anchorEl={anchorEl.anchor}
            open={Boolean(anchorEl.id == 1)}
            onClose={handleShareClose}
            onClick={handleShareClose}
          >
            <MenuItem onClick={() => handleCopyLink(1)}>
              <SvgIcon fontSize="small" sx={{ marginRight: 2 }}>
                <Copy05Icon />
              </SvgIcon>{' '}
              Copy link
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={12}>
          {isLocationAnalyticsLoading && (
            <Skeleton height={300} width={'100%'} />
          )}
          {!isLocationAnalyticsLoading && (
            <Box sx={{ bgcolor: 'transparent' }} ref={chartRef}>
              <Chart
                chartType="GeoChart"
                width="100%"
                height="400px"
                data={locationAnalyticsData?.analyticsData}
                options={{
                  region: 'IN',
                  displayMode: 'regions',
                  resolution: 'provinces',
                  colorAxis: { colors: ['#F7D178', '#F7D178'] },
                  sizeAxis: { minValue: 0, maxValue: 100 },
                  legend: 'none',
                  backgroundColor: colorWhite,
                  datalessRegionColor: colorWhite,
                  defaultColor: colorWhite,
                  magnifyingGlass: { enable: true, zoomFactor: 10.5 }
                }}
                mapsApiKey={process.env.REACT_APP_GOOGLE_CALENDAR_API}
              />
            </Box>
          )}{' '}
        </Grid>
      </Grid>
      <Dialog
        sx={{ borderRadius: '8px' }}
        open={chart !== ''}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ borderRadius: 10 }}>
          <Box textAlign={'end'}>
            <IconButton size="small" onClick={handleClose}>
              <SvgIcon sx={{ height: 20, width: 20 }}>
                <XClose />
              </SvgIcon>
            </IconButton>
            <Legend series={[]} />
          </Box>

          {chart == 1 && (
            <Chart
              chartType="GeoChart"
              width="100%"
              height={'60vh'}
              data={locationAnalyticsData?.analyticsData}
              options={{
                region: 'IN',
                displayMode: 'regions',
                resolution: 'provinces',
                colorAxis: { colors: [colorPrimary, colorPrimary] },
                sizeAxis: { minValue: 0, maxValue: 100 },
                legend: 'none',
                backgroundColor: colorWhite,
                datalessRegionColor: colorWhite,
                defaultColor: colorWhite,
                magnifyingGlass: { enable: true, zoomFactor: 10.5 }
              }}
              mapsApiKey={process.env.REACT_APP_GOOGLE_CALENDAR_API}
            />
          )}
        </DialogContent>
      </Dialog>
      {copySuccess && <SnackBarBox message="Link copied!" type={'success'} />}{' '}
    </>
  );
};

export default LocationAnalytics;

import {
  Autocomplete,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Rating,
  Skeleton,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomBreadcrumb from '../../components/Breadcrumb';
import {
  createTitle,
  getOptionLabel,
  handleError,
  isArrayNotEmpty
} from '../../utils/utils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllDomains,
  getAllIndicators
} from '../../redux/reducers/rubricSlice';
import { useDropzone } from 'react-dropzone';
import {
  UPLOAD_FILES_ALLOWED_EXTENSION,
  uploadFileType,
  uploadFileTypeIcon,
  uploadFilesAcceptedExtensions
} from '../../utils/constant';
import { Close, Download, Upload } from '@mui/icons-material';
import _ from 'lodash';
import SnackBarBox from '../../components/SnackBarBox';
import { LoadingButton } from '@mui/lab';
import {
  colorLinkGrey,
  colorPrimary,
  colorRatingStarOrange,
  colorSecondary,
  colorTextBlack,
  colorTextBorder,
  colorTextGrey
} from '../../config/theme';
import JSZip from 'jszip';
import {
  createUpdateEvidence,
  deleteAttachment,
  getEvidenceDetails,
  revertEvidenceFormData
} from '../../redux/reducers/observationSlice';
import { saveAs } from 'file-saver';
import {
  ChevronRightIcon,
  DownloadIcon,
  UploadIcon
} from '../../utils/iconSvg';
import { BootstrapDialog } from '../../utils/styledLayout';
import { CustomTextLink } from '../login/LoginForm';

const EvidenceCard = props => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [listOfAttachments, setListOfAttachments] = useState([]);
  const [selectedDomainId, setSelectedDomainId] = useState(null);
  const [attachmentUploadError, setAttachmentUploadError] = useState({
    error: false,
    errorMessage: ''
  });
  const [isHovered, setIsHovered] = useState(null);
  const [base64Content, setBase64Content] = useState('');
  const [showModal, setShowModal] = useState(null);
  const { indicatorsList, domains } = useSelector(state => state.rubric);
  const { authData } = useSelector(state => state.auth);
  const {
    isCreateUpdateEvidenceLoading,
    isCreateUpdateEvidenceSuccess,
    isCreateUpdateEvidenceFailed,
    createUpdateEvidenceErrorContainer,
    isEvidenceDetailSuccess,
    isEvidenceDetailLoading,
    evidenceDetail,
    deleteEvidenceLoading,
    deleteEvidenceFailed,
    deleteEvidenceSuccess,
    deleteEvidenceErrorContainer
  } = useSelector(state => state.observation);

  const onDrop = acceptedFiles => {
    setAttachmentUploadError({
      error: false,
      errorMessage: ''
    });

    if (listOfAttachments.length >= 5) {
      setAttachmentUploadError({
        error: true,
        errorMessage: 'You can only upload up to 5 attachments.'
      });
      return;
    }

    let file = acceptedFiles[0];

    // const maxSizeMB = _.includes(['video/mp4'], file.type) ? 20 : 5;
    const maxSizeMB = 20;
    const fileName = file ? file?.name : 'NA';
    if (uploadFilesAcceptedExtensions.includes(file.type)) {
      if (file.size / 1024 / 1024 <= maxSizeMB) {
        const reader = new FileReader();

        reader.onload = e => {
          const attachment = e.target.result;

          let attachmentObj = {
            attachmentId: 0,
            file: attachment,
            fileName: fileName,
            type: uploadFileType[file.type]
          };

          setListOfAttachments([...listOfAttachments, attachmentObj]);
        };
        reader.readAsDataURL(file);
      } else {
        setAttachmentUploadError({
          error: true,
          errorMessage: `File size exceeds the maximum allowed (${maxSizeMB} MB).`
        });
      }
    } else {
      setAttachmentUploadError({
        error: true,
        errorMessage: 'Please upload proper file format.'
      });
    }

    setTimeout(() => {
      setAttachmentUploadError({
        error: false,
        errorMessage: ''
      });
    }, 10000);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: props.type === 'view'
  });

  useEffect(() => {
    document.title = createTitle('Evidence Card');

    dispatch(getAllDomains());
    dispatch(getAllIndicators());

    if (location && location.state?.evidenceId) {
      dispatch(getEvidenceDetails(location.state?.evidenceId));
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isCreateUpdateEvidenceLoading && isCreateUpdateEvidenceSuccess) {
      handleBackNavigation();
      setLoading(false);
      return;
    }

    if (!isCreateUpdateEvidenceLoading && isCreateUpdateEvidenceFailed) {
      setLoading(false);
    }
  }, [isCreateUpdateEvidenceSuccess, isCreateUpdateEvidenceFailed]);

  const formik = useFormik({
    initialValues: {
      domain: null,
      indicator: null,
      rating: 0
    },
    validationSchema: Yup.object().shape({
      domain: Yup.object()
        .shape({
          id: Yup.string()
        })
        .required('Domain is required'),
      indicator: Yup.object()
        .shape({
          id: Yup.string()
        })
        .required('Indicator is required'),
      rating: Yup.number()
        .required('Rating is required')
        .min(0.5, 'Rating must be at least .5')
        .max(5, 'Rating cannot be more than 5')
    }),
    onSubmit: async values => {
      setLoading(true);
      const formData = new FormData();
      let payload = {
        observationId:
          location.pathname ===
          '/observations/observation-report/create-evidence-card'
            ? location.state?.observationId
            : null,
        domainId: values.domain.id,
        indicatorId: values.indicator.id,
        averageRating: values.rating,
        loggedInUserName: authData.userName
      };

      if (listOfAttachments.length > 0) {
        const zip = new JSZip();

        listOfAttachments
          .filter(cc => cc.attachmentId === 0)
          .forEach(attach => {
            const base64String = attach.file.split(',')[1];
            zip.file(attach.fileName, base64String, { base64: true });
          });

        await zip.generateAsync({ type: 'blob' }).then(res => {
          formData.append('file', res, 'attachments.zip');
        });
      } else {
        setLoading(false);
        setAttachmentUploadError({
          error: true,
          errorMessage: 'One attachment should be available'
        });

        setTimeout(() => {
          setAttachmentUploadError({
            error: false,
            errorMessage: ''
          });
        }, 3000);
        return;
        // formData.append('file', null);
      }
      formData.append('evidenceInfo', JSON.stringify(payload));

      dispatch(
        createUpdateEvidence({
          payload: formData,
          evidenceId: evidenceDetail?.evidenceId || null
        })
      );
    }
  });

  useEffect(() => {
    if (!isEvidenceDetailLoading && evidenceDetail) {
      if (isArrayNotEmpty(evidenceDetail.attachmentResponse)) {
        let tempListOfAttachment = [];
        evidenceDetail.attachmentResponse.forEach(element => {
          tempListOfAttachment.push({
            attachmentId: element.attachmentId,
            file: element.fileUrl,
            fileName: element.fileName,
            type: element.fileType
          });
        });
        setListOfAttachments([...tempListOfAttachment]);
      }
      formik.setValues({
        domain: {
          id: evidenceDetail.domainId,
          label: evidenceDetail.domainName
        },
        indicator: {
          id: evidenceDetail.indicatorId,
          label: evidenceDetail.indicatorName
        },
        rating: evidenceDetail.averageRating
      });
      setSelectedDomainId(evidenceDetail.domainId);
      setLoading(false);
    }
  }, [isEvidenceDetailSuccess]);

  const removeAttachment = (index, attachmentId) => {
    setListOfAttachments(listOfAttachments.filter((_, i) => i !== index));
    if (attachmentId) {
      dispatch(deleteAttachment(attachmentId));
    }
  };

  const handleBackNavigation = () => {
    // dispatch()
    if (props.type !== 'view') {
      navigate(
        `/observations/${
          location.pathname !==
          '/observations/observation-report/create-evidence-card'
            ? location.state?.observationId
              ? 'edit-observation'
              : 'new-observation'
            : 'observation-report'
        }`,
        {
          state: { ...location.state },
          replace: true
        }
      );
    } else {
      navigate(`/observations/observation-report/`, {
        state: { ...location.state },
        replace: true
      });
    }
  };

  const handleDownload = (imageUrl, fileName) => {
    if (imageUrl) {
      const link = document.createElement('a');
      link.href = imageUrl;
      link.target = '_blank';
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Image URL is not set');
    }
  };

  const handleBoxClick = (attachment, key) => {
    setBase64Content(attachment?.file);
    setShowModal({ ...attachment, key: key });
  };
  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'}>
        <Typography fontSize={18} fontWeight="medium" marginBottom={1}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={
              <SvgIcon sx={{ height: 24, width: 24 }}>
                <ChevronRightIcon color={colorTextGrey} />
              </SvgIcon>
            }
            sx={{ marginBottom: 2 }}
          >
            <Link
              color="inherit"
              href={'role'}
              onClick={event => {
                event.preventDefault();
                let path =
                  location.pathname ===
                  '/observations/edit-observation/evidence-card'
                    ? '/observations/edit-observation'
                    : location.pathname ===
                        '/observations/new-observation/evidence-card'
                      ? '/observations/new-observation'
                      : location.pathname ===
                          '/observations/observation-report/create-evidence-card'
                        ? '/observations/observation-report'
                        : '/observations';
                navigate(path, { state: location.state });
              }}
              sx={{ textDecoration: 'none' }}
            >
              <Typography
                fontWeight={'700'}
                color={colorLinkGrey}
                fontSize={'24px'}
              >
                {location.pathname ==
                '/observations/new-observation/evidence-card'
                  ? 'New Observation'
                  : location.pathname ==
                      '/observations/observation-report/create-evidence-card'
                    ? 'Observation Report'
                    : 'Observation'}{' '}
              </Typography>
            </Link>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: '700',
                color: colorTextBlack
              }}
            >
              Evidence Card
            </Typography>
          </Breadcrumbs>
        </Typography>
      </Grid>

      {loading && (
        <Skeleton
          variant="rounded"
          width={'100%'}
          height={400}
          animation="wave"
        />
      )}
      {!loading && (
        <>
          <Box
            sx={{
              width: '100%'
            }}
            bgcolor={'white'}
            padding={2}
            borderRadius={2}
            marginBottom={2}
          >
            <Grid container spacing={2}>
              <Grid item sm={12} md={12}>
                <Typography fontWeight={'700'} fontSize={'20px'}>
                  Evidence card
                </Typography>
              </Grid>
              <Grid item sm={12} md={12}>
                <InputLabel
                  sx={{
                    mb: 0.2,
                    fontWeight: '700',
                    color: colorTextBlack,
                    fontSize: '16px'
                  }}
                >
                  Select domain
                </InputLabel>
                <FormControl variant="outlined" fullWidth size="small">
                  <Autocomplete
                    disabled={props.type === 'view'}
                    size="small"
                    name="domain"
                    value={formik.values.domain}
                    onChange={(e, newValue) => {
                      formik.setFieldValue('domain', newValue);
                      setSelectedDomainId(newValue ? newValue.id : null);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === (value ? value.id : null) ||
                      option.label === (value ? value.label : null)
                    }
                    getOptionLabel={getOptionLabel}
                    options={
                      isArrayNotEmpty(domains)
                        ? domains.map(c => {
                            return {
                              label: c.domainName,
                              id: c.domainId
                            };
                          })
                        : []
                    }
                    sx={{ width: '416px' }}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          placeholder="Search"
                          error={formik.touched.domain && formik.errors.domain}
                          helperText={
                            formik.touched.domain && formik.errors.domain
                          }
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ marginRight: 0 }}
                              >
                                <SvgIcon
                                  sx={{ width: '16px', height: '16px', ml: 1 }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14 14L11.6667 11.6667M13.3333 7.66667C13.3333 10.7963 10.7963 13.3333 7.66667 13.3333C4.53705 13.3333 2 10.7963 2 7.66667C2 4.53705 4.53705 2 7.66667 2C10.7963 2 13.3333 4.53705 13.3333 7.66667Z"
                                      stroke="#ABB4BD"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </SvgIcon>
                              </InputAdornment>
                            )
                          }}
                        />
                      );
                    }}
                    ListboxProps={{
                      sx: {
                        '& .MuiAutocomplete-option:hover': {
                          backgroundColor: colorSecondary
                        }
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={12}>
                <InputLabel
                  sx={{
                    mb: 0.2,
                    fontWeight: '700',
                    color: colorTextBlack,
                    fontSize: '16px'
                  }}
                >
                  Select indicator
                </InputLabel>
                <FormControl variant="outlined" fullWidth size="small">
                  <Autocomplete
                    disabled={props.type === 'view'}
                    size="small"
                    name="indicator"
                    value={formik.values.indicator}
                    onChange={(_, newValue) =>
                      formik.setFieldValue('indicator', newValue)
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === (value ? value.id : null) ||
                      option.label === (value ? value.label : null)
                    }
                    getOptionLabel={getOptionLabel}
                    options={
                      isArrayNotEmpty(indicatorsList)
                        ? indicatorsList
                            .filter(
                              items => items.domainId === selectedDomainId
                            )
                            .map(c => {
                              return {
                                label: c.indicatorName,
                                id: c.indicatorId
                              };
                            })
                        : []
                    }
                    sx={{ width: '416px' }}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          placeholder="Search"
                          error={
                            formik.touched.indicator && formik.errors.indicator
                          }
                          helperText={
                            formik.touched.indicator && formik.errors.indicator
                          }
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ marginRight: 0 }}
                              >
                                <SvgIcon
                                  sx={{ width: '16px', height: '16px', ml: 1 }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14 14L11.6667 11.6667M13.3333 7.66667C13.3333 10.7963 10.7963 13.3333 7.66667 13.3333C4.53705 13.3333 2 10.7963 2 7.66667C2 4.53705 4.53705 2 7.66667 2C10.7963 2 13.3333 4.53705 13.3333 7.66667Z"
                                      stroke="#ABB4BD"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </SvgIcon>
                              </InputAdornment>
                            )
                          }}
                        />
                      );
                    }}
                    ListboxProps={{
                      sx: {
                        '& .MuiAutocomplete-option:hover': {
                          backgroundColor: colorSecondary
                        }
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={12}>
                <FormControl variant="outlined" fullWidth size="small">
                  <Typography
                    fontWeight="700"
                    component="section"
                    fontSize={'16px'}
                    color={colorTextBlack}
                  >
                    Average rating
                  </Typography>
                  <Grid display={'flex'}>
                    <Rating
                      name="rating"
                      onChange={(e, newValue) =>
                        formik.setFieldValue('rating', newValue)
                      }
                      disabled={props.type === 'view'}
                      value={formik.values.rating}
                      precision={0.5}
                      sx={{ marginRight: 2, color: colorRatingStarOrange }}
                    />

                    <Typography
                      // fontSize={'20px'}
                      fontWeight={'700'}
                      color={'inherit'}
                    >
                      {`(${formik.values.rating ? formik.values.rating?.toFixed(1) : '0.0'})`}
                    </Typography>
                  </Grid>
                </FormControl>{' '}
                {formik.touched.rating && formik.errors.rating && (
                  <FormHelperText sx={{ textAlign: 'start' }}>
                    {formik.errors.rating}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={12} md={12}>
                <FormControl variant="outlined" fullWidth size="small">
                  <Typography
                    fontWeight="700"
                    component="section"
                    fontSize={'16px'}
                    color={colorTextBlack}
                  >
                    Upload files
                  </Typography>
                  <Box
                    {...getRootProps()}
                    elevation={0}
                    sx={{
                      p: 2,
                      mt: 1,
                      width: '416px',
                      height: '123px',
                      border: `2px dashed ${isDragActive ? colorPrimary : colorTextGrey}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: isDragActive
                        ? colorSecondary
                        : 'inherit',
                      transition: 'background-color 0.3s',
                      textAlign: 'center'
                    }}
                  >
                    <input
                      disabled={props.type === 'view'}
                      {...getInputProps()}
                      accept={UPLOAD_FILES_ALLOWED_EXTENSION}
                    />
                    <Grid>
                      {isDragActive ? (
                        'Drop the files here...'
                      ) : (
                        <Grid>
                          <UploadIcon />
                          <Typography
                            sx={{ fontSize: '14px', fontWeight: '600' }}
                            color={colorLinkGrey}
                          >
                            Drag & drop or, browser your files
                          </Typography>
                          <Typography
                            sx={{ fontSize: '12px', fontWeight: '500' }}
                            color={colorLinkGrey}
                          >
                            Supported files types:
                            {` ${UPLOAD_FILES_ALLOWED_EXTENSION.join(', ')}`}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Box>

                  {/* */}
                </FormControl>
              </Grid>
              {isArrayNotEmpty(listOfAttachments) && (
                <Grid item sm={12} md={12}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Typography
                      fontWeight="700"
                      component="section"
                      fontSize={'16px'}
                      mb={1}
                      color={colorTextBlack}
                    >
                      Attachments
                    </Typography>
                    <Box sx={{ width: '420px' }}>
                      {listOfAttachments.map((attach, key) => {
                        return (
                          <Box
                            key={key}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '3px 1rem',
                              border: `1px solid ${isHovered === key ? colorPrimary : colorTextBorder}`,
                              borderRadius: '7px',
                              background:
                                isHovered === key ? colorSecondary : 'inherit',
                              marginBottom: '5px'
                            }}
                          >
                            <Box
                              onMouseEnter={() => setIsHovered(key)}
                              onMouseLeave={() => setIsHovered(null)}
                              onClick={e => {
                                handleBoxClick(attach, key);
                              }}
                              sx={{
                                width: '100%',
                                mr: 1,
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer'
                              }}
                            >
                              {uploadFileTypeIcon[attach.type]}
                              <Typography
                                fontWeight="600"
                                component="section"
                                fontSize={'12px'}
                                color={colorTextBlack}
                                sx={{
                                  marginLeft: 1,
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '92%',
                                  width: '18rem'
                                }}
                              >
                                {attach.fileName}
                              </Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                              {props.type === 'view' && (
                                <IconButton
                                  size="small"
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleDownload(
                                      attach.file,
                                      attach.fileName
                                    );
                                  }}
                                >
                                  <SvgIcon
                                    sx={{ height: '18px', width: '18px' }}
                                  >
                                    <DownloadIcon />
                                  </SvgIcon>
                                </IconButton>
                              )}
                              <IconButton
                                size="small"
                                disabled={props.type === 'view'}
                                onClick={e => {
                                  e.preventDefault();
                                  removeAttachment(
                                    key,
                                    attach.attachmentId !== 0
                                      ? attach.attachmentId
                                      : null
                                  );
                                }}
                              >
                                <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.3337 4.66675L4.66699 11.3334M4.66699 4.66675L11.3337 11.3334"
                                      stroke={colorTextBlack}
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </SvgIcon>
                                {/* <Close /> */}
                              </IconButton>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Box>
          <Grid
            bgcolor={'white'}
            padding={2}
            item
            sm={12}
            md={12}
            gap={2}
            display={'flex'}
            borderRadius={2}
          >
            <Button
              variant="outlined"
              onClick={() => handleBackNavigation()}
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: colorTextBlack,
                borderWidth: '2px',
                height: '42px',
                width: '106px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              onClick={() => formik.handleSubmit()}
              loading={loading}
              disabled={
                props.type === 'view' ||
                formik.values.domain === null ||
                formik.values.indicator === null ||
                formik.values.rating === 0 ||
                !isArrayNotEmpty(listOfAttachments)
              }
              type="submit"
              variant="contained"
              // style={{ marginRight: 24 }}
              load
              sx={{ width: '124px', height: '42px' }}
            >
              Save card
            </LoadingButton>
          </Grid>
        </>
      )}

      <Dialog
        maxWidth="sm"
        fullWidth
        open={!!showModal}
        onClose={() => {
          setShowModal(null);
          setBase64Content('');
        }}
        PaperProps={{ component: 'form' }}
      >
        <DialogTitle
          sx={{
            padding: '24px',
            backgroundColor: 'white',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '26px'
          }}
          id="customized-dialog-title"
        >
          {_.includes(['jpeg', 'jpg', 'png'], showModal?.type)
            ? 'Image'
            : _.includes(['mp4'], showModal?.type)
              ? 'Video'
              : _.includes(['mp3'], showModal?.type)
                ? 'Music'
                : _.includes(['pdf', 'txt', 'csv'], showModal?.type)
                  ? 'File'
                  : ''}
        </DialogTitle>

        <DialogContent sx={{ px: '24px' }}>
          <>
            <TextField
              size="small"
              sx={{ height: '42px', width: '100%' }}
              value={showModal?.fileName}
              InputProps={{ readOnly: true }}
            />

            <Grid display={'flex'} alignItems={'center'} py={2}>
              {_.includes(['jpeg', 'jpg', 'png'], showModal?.type) && (
                <img
                  src={base64Content}
                  alt={base64Content}
                  style={{ width: '100%', maxHeight: '80vh' }}
                />
              )}

              {_.includes(['mp4'], showModal?.type) && (
                <video
                  controls
                  src={base64Content}
                  style={{ width: '100%', maxHeight: '80vh' }}
                />
              )}

              {_.includes(['mp3'], showModal?.type) && (
                <audio
                  controls
                  src={base64Content}
                  style={{ width: '100%', maxHeight: '80vh' }}
                />
              )}

              {_.includes(['pdf'], showModal?.type) && (
                <>
                  {base64Content?.startsWith('data:') ? (
                    <iframe
                      src={base64Content}
                      style={{ width: '100%', height: '80vh' }}
                      title="PDF File"
                    />
                  ) : (
                    <iframe
                      src={base64Content}
                      style={{ width: '100%', height: '80vh' }}
                      title="PDF File"
                    />
                  )}
                </>
              )}

              {_.includes(['txt'], showModal?.type) && (
                <>
                  {base64Content?.startsWith('data:') ? (
                    <textarea
                      value={atob(base64Content.split(',')[1])}
                      readOnly
                      style={{ width: '100%', height: '80vh' }}
                    />
                  ) : (
                    <iframe
                      src={base64Content}
                      style={{ width: '100%', height: '80vh' }}
                      title="Text/CSV File"
                    />
                  )}
                </>
              )}
              {_.includes(['csv'], showModal?.type) && (
                <>
                  <Typography mr={1}>
                    To view the CSV file, please download it.
                  </Typography>
                  <CustomTextLink
                    fontWeight="600"
                    onClick={() => {
                      handleDownload(showModal?.file, showModal?.title);
                    }}
                    target={'_blank'}
                    title="Download file"
                  />
                </>
              )}
            </Grid>
          </>
        </DialogContent>
        <DialogActions sx={{ p: '0px 24px 24px 24px' }}>
          <Grid container gap={2} sx={{}}>
            <Button
              variant="outlined"
              onClick={() => setShowModal(null)}
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: colorTextBlack,
                borderWidth: '2px',
                height: '42px',
                width: '106px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={props.type === 'view'}
              onClick={e => {
                e.stopPropagation();
                setShowModal(null);
                removeAttachment(showModal?.key, showModal?.attachmentId);
              }}
              sx={{
                width: '106px',
                height: '42px',
                boxShadow: 'none'
              }}
            >
              Delete
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      {attachmentUploadError.error && (
        <SnackBarBox
          type="error"
          message={handleError(attachmentUploadError.errorMessage)}
        />
      )}

      {!deleteEvidenceLoading && deleteEvidenceFailed && (
        <SnackBarBox
          type="error"
          message={handleError(deleteEvidenceErrorContainer.errorMessage)}
        />
      )}

      {!deleteEvidenceLoading && deleteEvidenceSuccess && (
        <SnackBarBox
          type="success"
          message={'Attachment deleted successfully....'}
        />
      )}

      {!isCreateUpdateEvidenceLoading && isCreateUpdateEvidenceFailed && (
        <SnackBarBox
          type="error"
          message={handleError(
            createUpdateEvidenceErrorContainer?.errorMessage
          )}
        />
      )}
    </>
  );
};

export default EvidenceCard;
